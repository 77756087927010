body {
  margin: 0;
  overflow: hidden;
  background-color: black;
}

.instruction-container {
  width: 100%;
  height: 100lvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.instruction-card {
  min-width: 500px;
  min-height: 500px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: monospace;
  font-size: 24px;
  color: white;
  text-align: center;

  border: 10px solid white;
}

.links {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
}

.links > a,
a:visited {
  text-decoration: none;
  color: purple;
  font-size: 28px;
}

.links > a:hover {
  color: lime;
}

.bottom-text {
  margin-top: 50px;
  color: yellowgreen;
  font-size: 24px;
}

.title-gradient {
  width: 270px;
  height: 120px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 12.5%,
    rgba(206, 255, 0, 1) 25%,
    rgba(1, 244, 0, 1) 37.5%,
    rgba(0, 255, 239, 1) 50%,
    rgba(42, 0, 255, 1) 62.5%,
    rgba(255, 0, 249, 1) 75%,
    rgba(255, 0, 138, 1) 87.5%,
    rgba(255, 0, 0, 1) 100%
  );
  background-size: 500% 100%;
  -webkit-mask-image: url("./public/images/title-mask.png");
  mask-image: url("./public/images/title-mask.png");
  animation: gradient 60s ease infinite;
  margin-bottom: 30px;
}

.cookies-text {
  font-size: 14px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 500px) {
  .instruction-card {
    min-width: 300px;
    padding: 25px 15px;
    font-size: 20px;
  }
}
