body {
  background-color: #000;
  margin: 0;
  overflow: hidden;
}

.instruction-container {
  width: 100%;
  height: 100lvh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.instruction-card {
  min-width: 500px;
  min-height: 500px;
  color: #fff;
  text-align: center;
  border: 10px solid #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  font-family: monospace;
  font-size: 24px;
  display: flex;
}

.links {
  width: 100%;
  justify-content: space-around;
  padding: 5px 0;
  display: flex;
}

.links > a, a:visited {
  color: purple;
  font-size: 28px;
  text-decoration: none;
}

.links > a:hover {
  color: #0f0;
}

.bottom-text {
  color: #9acd32;
  margin-top: 50px;
  font-size: 24px;
}

.title-gradient {
  width: 270px;
  height: 120px;
  background: linear-gradient(90deg, red 0%, #ff9a00 12.5%, #ceff00 25%, #01f400 37.5%, #00ffef 50%, #2a00ff 62.5%, #ff00f9 75%, #ff008a 87.5%, red 100%) 0 0 / 500% 100%;
  margin-bottom: 30px;
  animation: 60s infinite gradient;
  -webkit-mask-image: url("title-mask.7e729282.png");
  mask-image: url("title-mask.7e729282.png");
}

.cookies-text {
  font-size: 14px;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@media screen and (max-width: 500px) {
  .instruction-card {
    min-width: 300px;
    padding: 25px 15px;
    font-size: 20px;
  }
}

/*# sourceMappingURL=index.680a4ae7.css.map */
